<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}培训</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="培训名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="培训形式" prop="trainingFormCode">
				<el-select v-model="ruleForm.trainingFormCode" :disabled="isShowDetail" placeholder="请选择">
					<el-option label="线上" value="U"></el-option>
					<el-option label="线下" value="D"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="培训时间" prop="registerTime">
				<el-date-picker
					v-model="ruleForm.registerTime"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="yyyy-MM-dd HH:mm:ss"
					:disabled="isShowDetail"
					:picker-options="activityTimePickerOptions"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="报名时间" prop="applicationDate">
				<el-date-picker
					v-model="ruleForm.applicationDate"
					:picker-options="secondPickerOptions"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"
					:disabled="isShowDetail"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="详细地址" class="attractionAddress" prop="address">
				<el-input
					v-model.trim="ruleForm.address"
					disabled
					placeholder="请选择详细地址"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="principalName" label="联系人">
				<el-input v-model.trim="ruleForm.principalName" :disabled="isShowDetail" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="traineeCount" label="培训人数">
				<el-input v-model.trim="ruleForm.traineeCount" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="price" label="培训费用">
				<el-input v-model.trim="ruleForm.price" type="number" :disabled="isShowDetail" placeholder="请输入">
					<template slot="append">元</template>
				</el-input>
			</el-form-item>
			<el-form-item label="培训介绍" prop="description">
				<el-input type="textarea" autosize :disabled="isShowDetail" :rows="2" v-model.trim="ruleForm.description"></el-input>
			</el-form-item>
			<el-form-item label="培训图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="TRAIN"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="6"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<choose-address ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		chooseAddress: () => import('./chooseAddress.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				traineeCount: '',
				price: '',
				applicationDate: '',
				registerTime: '',
				description: '',
				address: '',
				principalName: '',
				phoneNumber: '',
				trainingFormCode: '', //培训形式
				images: [],
			},

			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				traineeCount: [{ required: true, message: '请输入', trigger: 'blur' }],
				price: [{ required: true, message: '请输入', trigger: 'blur' }],
				trainingFormCode: [{ required: true, message: '请选择', trigger: 'change' }],
				applicationDate: [{ required: true, message: '请选择', trigger: 'change' }],
				registerTime: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				principalName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
			},
			attachmentInfo: [],
			saveLoading: false,
			link: '',
			secondPickerOptions: {
				disabledDate: (time) => {
					if (this.ruleForm.registerTime && this.ruleForm.registerTime.length > 0) {
						return time.getTime() > new Date(this.ruleForm.registerTime[1]).getTime();
					} else {
						return false;
					}
				},
			},
			activityTimePickerOptions: {
				disabledDate: (time) => {
					if (this.ruleForm.registrationTime && this.ruleForm.registrationTime.length > 0) {
						return time.getTime() < new Date(this.ruleForm.registrationTime[0]).getTime();
					} else {
						return false;
					}
				},
			},
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachment();
			this.link = data.link;
			if (data.row) {
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
					this.setRuleForm(data.row);
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'STUDY_TRAIN' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 设置表单数据
		setRuleForm(data) {
			this.ruleForm = {
				...data,
				price: data.price ? data.price / 100 : '',
				applicationDate: data.registerStartTime && data.registerEndTime ? [data.registerStartTime, data.registerEndTime] : [],
				registerTime: data.startTime && data.endTime ? [data.startTime, data.endTime] : [],
				images: data.attachmentMap['PHOTO'].map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				}),
			};
		},
		// 选择地址
		chooseAddress() {
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			const { addressData } = data;
			this.ruleForm.address =
				addressData.province + addressData.city + addressData.district + addressData.township + addressData.street + addressData.streetNumber;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.ruleForm.regionCode = addressData.adcode;
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						price: this.ruleForm.price ? Number(this.ruleForm.price) * 100 : 0,
						startTime: this.ruleForm.registerTime && this.ruleForm.registerTime.length > 0 ? this.ruleForm.registerTime[0] : '',
						endTime: this.ruleForm.registerTime && this.ruleForm.registerTime.length > 0 ? this.ruleForm.registerTime[1] : '',
						registerStartTime: this.ruleForm.applicationDate && this.ruleForm.applicationDate.length > 0 ? this.ruleForm.applicationDate[0] : '',
						registerEndTime: this.ruleForm.applicationDate && this.ruleForm.applicationDate.length > 0 ? this.ruleForm.applicationDate[1] : '',
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input,
		.el-date-editor {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.addressClass {
		&-content {
			width: calc((100% - 32px) / 3);
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>